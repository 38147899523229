<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
  >
    <!-- style="background-image: url('media/illustrations/progress-hd.png')"-->
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <!-- <a href="#" class="mb-12">
        <img alt="Logo" src="media/logos/logo-2-dark.svg" class="h-45px" />
      </a> -->
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100"
          id="kt_login_signin_form"
          @submit="onSubmitLogin"
          :validation-schema="login"
        >
          <!--begin::Heading-->
          <div class="text-center mb-10">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">
              เข้าสู่ระบบ
            </h1>
            <!--end::Title-->

            <!--begin::Link-->
          
            <!--end::Link-->
          </div>
          <!--begin::Heading-->

          <!-- <div class="mb-10 bg-light-info p-8 rounded">
            <div class="text-info">
              Use account <strong>prommin.intani@gmail.com</strong> and password
              <strong>dreamp1411</strong> to continue.
            </div>
          </div> -->
          <div class="row g-3 mb-9">
            <div class="col-12 col-md-12">
              <a
                :href="getGoogleUrl(from)"
                class="btn btn-flex  btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                type="button"
                style="border: 1px solid #EFF2F5;"
              >
                <img
                  alt="Logo"
                  src="media/svg/brand-logos/google-icon.svg"
                  class="h-25px m-2"
                />
                เข้าสู่ระบบด้วยบัญชี Google
              </a>
            </div>
            <!-- <div class="col-12 col-md-6">
              <v-facebook-login
                app-id="630550314599643"
                style="border: 1px solid #EFF2F5;"
                @login="loginFacebook"
                class="btn  btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
                logo-class="m-2"
                @sdk-init="setText"
              >
              </v-facebook-login>
            </div> -->
          </div>
          <div class=" separator-content my-5" style="text-align: center;">
            <span class="w-125px text-gray-500 fw-semibold fs-7"
              >หรือเข้าสู่ระบบด้วยบัญชีผู้ใช้งาน</span
            >
          </div>
          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Label-->
            <label class="form-label fs-6 fw-bolder text-dark"
              >บัญชีผู้ใช้งาน</label
            >
            <!--end::Label-->

            <!--begin::Input-->
            <Field
              class="form-control form-control-lg form-control-solid"
              type="text"
              name="username"
              autocomplete="off"
              v-on:keydown.enter.prevent='' 
            />
            <!--end::Input-->
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="username" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-stack mb-2">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6 mb-0"
                >รหัสผ่าน</label
              >
           
            </div>
            <!--end::Wrapper-->

            <!--begin::Input-->
            <div style="position: relative;">
            <Field
              class="form-control form-control-lg form-control-solid"
              :type="passwordFieldType"
              name="password"
              autocomplete="off"
              v-on:keydown.enter.prevent='' 
            />
            <button
              style="position: absolute;top: 0;right: 5px;"
              type="button"
              class="btn"
              @click="switchVisibility"
            >
              <i class="fa fa-eye"></i>
            </button>
            </div>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
            <div class="d-flex  mb-2" style="justify-content: flex-end;">
              <router-link
                to="/password-reset"
                class="link-primary fs-6 fw-bolder"
              >
                ลืมรหัสผ่าน ?
              </router-link>
            </div>
          </div>
          <!--end::Input group-->

          <!--end::Link-->
          <!--begin::Actions-->
          <div class="text-center">
            <!--begin::Submit button-->
            <button
              type="submit"
              ref="submitButton"
              id="kt_sign_in_submit"
              class="btn btn-lg  w-100 mb-5"
              style="background: #323563;color: #ffffff"
            >
              <span class="indicator-label">
                เข้าสู่ระบบ
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <router-link
              to="/"
              class="btn btn-lg  w-100 mb-5"
              style="color: #323563;border: 1px solid #323563;"
            >
              กลับสู่หน้าหลัก
            </router-link>
              <div class="text-gray-400 fw-bold fs-4">
              ยังไม่มีบัญชีผู้ใช้งาน?

              <router-link to="/sign-up" class="link-primary fw-bolder ">
                <u>สมัครสมาชิก</u>
              </router-link>
            </div>
            <!--end::Separator-->
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <!-- <div class="d-flex align-items-center fw-bold fs-6">
        <a href="#" class="text-muted text-hover-primary px-2">About</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact</a>

        <a href="#" class="text-muted text-hover-primary px-2">Contact Us</a>
      </div> -->
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import VFacebookLogin from "vue-facebook-login-component-next";
/* eslint-disable */
import { getGoogleUrl } from "@/utils/getGoogleUrl";
import axios from "axios";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    VFacebookLogin,
  },
  data() {
    return {
      passwordFieldType: "password",
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const route = useRoute();
    const getUserInfo = (access_token) => {
      axios
        .get(`https://openidconnect.googleapis.com/v1/userinfo`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(async (response) => {
          // console.log(response.data);
          await store.dispatch(Actions.LOGIN, response.data);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          // console.log(store.state.AuthModule.user);

          // console.log(error);
          if (!error) {
            Swal.fire({
              text: "เข้าสู่ระบบสำเร็จ!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              // console.log(router);
              // setTimeout(() => {
              //   localStorage.setItem("reloaded", "1");
              //   window.location.href ="/home"
              // }, 1000);
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const getAccessToken = (code) => {
      axios
        .post(
          `https://oauth2.googleapis.com/token?code=${code}&client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&client_secret=${process.env.VUE_APP_GOOGLE_CLIENT_SECRET}&redirect_uri=${process.env.VUE_APP_GOOGLE_REDIRECT_URL}&grant_type=${process.env.VUE_APP_GOOGLE_GRANT_TYPE}`
        )
        .then((response) => {
          // console.log(response.data);
          getUserInfo(response.data.access_token);
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    if (route.query && route.query.code) {
      // console.log(route.query.code);
      getAccessToken(route.query.code);
    }
    const loginFacebook = (user) => {
      // console.log(user.authResponse.accessToken);
      axios
        .get(
          `https://graph.facebook.com/me?fields=id,picture,email,first_name,gender,last_name,name`,
          {
            headers: {
              Authorization: `Bearer ${user.authResponse.accessToken}`,
            },
          }
        )
        .then(async (response) => {
          // console.log(response.data);
          await store.dispatch(Actions.LOGIN, response.data);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          // console.log(store.state.AuthModule.user);
          // console.log(error);
          if (!error) {
            Swal.fire({
              text: "เข้าสู่ระบบสำเร็จ!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        // .email()
        .required("กรุณากรอกบัญชีผู้ใช้งาน"),
      password: Yup.string()
        .min(4)
        .required("กรุณากรอกรหัสผ่าน"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // console.log(values);
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      await store.dispatch(Actions.LOGIN, values);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      // console.log(store.state.AuthModule.user);

      // console.log(error);
      if (!error) {
        // Swal.fire({
        //   text: "เข้าสู่ระบบสำเร็จ!",
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "ตกลง",
        //   customClass: {
        //     confirmButton: "btn fw-semobold btn-light-primary",
        //   },
        // }).then(function() {
        //   localStorage.setItem("reloaded", "1");
        // router.push({ name: "home" });
        // });
        Swal.close()
        router.push({ name: "home" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    onMounted(() => {
      store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
        qulifiedName: "style",
      });
    });

    store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
      qulifiedName: "style",
      value: "background-image: url(media/patterns/header-bg.jpg)",
    });

    return {
      onSubmitLogin,
      login,
      submitButton,
      getGoogleUrl,
      getAccessToken,
      loginFacebook,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   const span: any = document.querySelector(".v-facebook-login span");
    //   console.log(span);
    //   if (span != null) {
    //     span.innerText = "เข้าสู่ระบบด้วยบัญชี Facebook";
    //   }
    // }, 20);
  },
  methods:{
    setText(){
      setTimeout(() => {
        const span: any = document.querySelector(".v-facebook-login span");
      // console.log(span);
      if (span != null) {
        span.innerText = "เข้าสู่ระบบด้วยบัญชี Facebook";
      }
      }, 20);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  }
});
</script>
