
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import VFacebookLogin from "vue-facebook-login-component-next";
/* eslint-disable */
import { getGoogleUrl } from "@/utils/getGoogleUrl";
import axios from "axios";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    VFacebookLogin,
  },
  data() {
    return {
      passwordFieldType: "password",
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const route = useRoute();
    const getUserInfo = (access_token) => {
      axios
        .get(`https://openidconnect.googleapis.com/v1/userinfo`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(async (response) => {
          // console.log(response.data);
          await store.dispatch(Actions.LOGIN, response.data);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          // console.log(store.state.AuthModule.user);

          // console.log(error);
          if (!error) {
            Swal.fire({
              text: "เข้าสู่ระบบสำเร็จ!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              // console.log(router);
              // setTimeout(() => {
              //   localStorage.setItem("reloaded", "1");
              //   window.location.href ="/home"
              // }, 1000);
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    const getAccessToken = (code) => {
      axios
        .post(
          `https://oauth2.googleapis.com/token?code=${code}&client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&client_secret=${process.env.VUE_APP_GOOGLE_CLIENT_SECRET}&redirect_uri=${process.env.VUE_APP_GOOGLE_REDIRECT_URL}&grant_type=${process.env.VUE_APP_GOOGLE_GRANT_TYPE}`
        )
        .then((response) => {
          // console.log(response.data);
          getUserInfo(response.data.access_token);
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    if (route.query && route.query.code) {
      // console.log(route.query.code);
      getAccessToken(route.query.code);
    }
    const loginFacebook = (user) => {
      // console.log(user.authResponse.accessToken);
      axios
        .get(
          `https://graph.facebook.com/me?fields=id,picture,email,first_name,gender,last_name,name`,
          {
            headers: {
              Authorization: `Bearer ${user.authResponse.accessToken}`,
            },
          }
        )
        .then(async (response) => {
          // console.log(response.data);
          await store.dispatch(Actions.LOGIN, response.data);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          // console.log(store.state.AuthModule.user);
          // console.log(error);
          if (!error) {
            Swal.fire({
              text: "เข้าสู่ระบบสำเร็จ!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(function() {
              router.push({ name: "home" });
            });
          } else {
            Swal.fire({
              text: error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "ลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "ลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    };
    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        // .email()
        .required("กรุณากรอกบัญชีผู้ใช้งาน"),
      password: Yup.string()
        .min(4)
        .required("กรุณากรอกรหัสผ่าน"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // console.log(values);
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      await store.dispatch(Actions.LOGIN, values);
      const [errorName] = Object.keys(store.getters.getErrors);
      const error = store.getters.getErrors[errorName];
      // console.log(store.state.AuthModule.user);

      // console.log(error);
      if (!error) {
        // Swal.fire({
        //   text: "เข้าสู่ระบบสำเร็จ!",
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "ตกลง",
        //   customClass: {
        //     confirmButton: "btn fw-semobold btn-light-primary",
        //   },
        // }).then(function() {
        //   localStorage.setItem("reloaded", "1");
        // router.push({ name: "home" });
        // });
        Swal.close()
        router.push({ name: "home" });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    onMounted(() => {
      store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
        qulifiedName: "style",
      });
    });

    store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
      qulifiedName: "style",
      value: "background-image: url(media/patterns/header-bg.jpg)",
    });

    return {
      onSubmitLogin,
      login,
      submitButton,
      getGoogleUrl,
      getAccessToken,
      loginFacebook,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   const span: any = document.querySelector(".v-facebook-login span");
    //   console.log(span);
    //   if (span != null) {
    //     span.innerText = "เข้าสู่ระบบด้วยบัญชี Facebook";
    //   }
    // }, 20);
  },
  methods:{
    setText(){
      setTimeout(() => {
        const span: any = document.querySelector(".v-facebook-login span");
      // console.log(span);
      if (span != null) {
        span.innerText = "เข้าสู่ระบบด้วยบัญชี Facebook";
      }
      }, 20);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  }
});
