export const getGoogleUrl = (from) => {
    // import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID
    const rootUrl = process.env.VUE_APP_GOOGLE_AUTH_URL;
  /* eslint-disable */
  // offline online
    const options = {
      redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'select_account',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
      ].join(' '),
      state: from,
    };
  
    const qs = new URLSearchParams(options);
  
    return `${rootUrl}?${qs.toString()}`;
  };
  
  